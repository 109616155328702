<template>
  <div class="view-home for-members-bg">
    <div class="container">
      <div class="row about-page pb-5">
        <div class="col-md-12">
          <div class="breadcrumb px-0">
            <router-link to="/">{{ $t('breadcrumb.main-page') }}</router-link>
            <router-link to="/for-members" class="active">{{ $t('navbar.for-teachers') }}</router-link>
          </div>
          <div class="row">
            <div class="teachers-wrapper col-md-12">
              <div class="left-wrapper">
                <h1 class="title about-title mb-3">{{ $t('navbar.for-teachers') }}</h1>
                <b v-html="$t('teacher_text')">

                </b>
                <br>
                <br>
                <span v-html="$t('teacher_ustaz')"></span>
                <div class="cert-wrapper">
                  <img height="256px" width="186px" src="images/teacher_certificate_template_2025_vertical.png" alt="">
                  <div>
                    <span v-html="$t('cert-example')"></span>
                    <a target="_blank" href="https://smartustaz.kz/">
                      <img width="217px" height="77px" src="images/ustaz.png" alt="">
                    </a>

                  </div>
                </div>
              </div>
              <div class="right-wrapper">
                <div class="right-max">
                  <h1 class="title about-title mb-3 teacher-title">{{$t('teacher-auth')}}</h1>
                  <LoginFormTeacher></LoginFormTeacher>
                  <div>{{$t('teacher-more')}}</div>
                  <div class="item-link"><img width="34px" height="33px" src="images/call-icon.png" alt=""> <a
                      href="tel:87273100258">8 727 310 02 58</a></div>
                  <div class="item-link"><img width="34px" height="33px" src="images/email-teacher.png" alt=""> <a
                      href="mailto:1000bala@el-umiti.kz<">1000bala@el-umiti.kz</a></div>
                  <div class="links">
                    <a href="https://t.me/smartustaz">
                      <img src="images/telegram-teacher.png" width="34px" height="33px">
                    </a>
                    <a href="https://www.instagram.com/el_umiti.kz/">
                      <img src="images/insta-teachers.png" width="34px" height="33px">
                    </a> <a href="https://www.youtube.com/channel/UC9nAqSXFXJSJi5qe1xBTNTA">
                    <img src="images/youtube-teachers.png" width="34px" height="33px">
                  </a>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoginFormTeacher from '@/components/LoginFormTeacher'

export default {
  components: {
    LoginFormTeacher,
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss">
.item-link {
  margin-top: 8px;

  img {
    margin-right: 16px;
  }
}

.links {
  margin-top: 15px;

  a {
    margin-right: 22px;
  }
}

.right-max {
  margin: 0 auto;
  max-width: 350px;
}

.teacher-title {
  width: 100%;
  text-align: center;
  display: block;
  color: black;
}

.cert-wrapper {
  margin-top: 26px;
  display: flex;

  div {
    max-width: 270px;
    margin-left: 44px;
    display: flex;
    flex-direction: column;

    span {
      display: block;
    }

    img {
      margin-top: 31px;
    }
  }
}

.teachers-wrapper {
  display: flex;
}

.left-wrapper {
  width: 60%;
}

.right-wrapper {
  width: 40%;
}

@media (max-width: 991px) {
  .teachers-wrapper {
    flex-direction: column;
  }
  .left-wrapper {
    width: 100%;
  }
  .right-wrapper {
    width: 100%;
    margin-top: 3rem;
  }
  .cert-wrapper {
    justify-content: center;
  }
}

@media (max-width: 500px) {
  .cert-wrapper {
    flex-direction: column;

    img {
      margin: auto;
    }

    div {
      margin: 2rem auto;
    }
  }
}
</style>
